/*******************************
EDV - R��lein
*******************************/

//  Fullscreen Main Window
$(document).ready(function(){
  function fullmainwindow(){
    var bodyHeight = $("body").height();
    var windowHeight = $(window).height();
    if (bodyHeight < windowHeight) {
     $("#main").css('minHeight', windowHeight - $('#header').outerHeight() - $('#footer').outerHeight() );
    };
    $("footer").css("display","block");
  };
  fullmainwindow();
  // Run the function in case of window resize
  jQuery(window).resize(function() {
    fullmainwindow();
  });
  // $('#header').scrollToFixed(); //
})

// Navigation
$(document).ready(function() {
  $('.navbar-open-btn').click(function() {
    document.getElementById("nav-navigation").style.height = "100%";
  });
  $('.nav-overlay-content li a').click(function() {
    document.getElementById("nav-navigation").style.height = "0%";
  });
    $('#nav-navigation span').click(function() {
    document.getElementById("nav-navigation").style.height = "0%";
  });
});

// Top-Link
$(document).ready(function() {
  $('div.nav-top-link').hide();
  $(window).scroll(function(){
    var value = 200;
    var scrolling = $(window).scrollTop();
    if (scrolling > value) {
      $('div.nav-top-link').fadeIn();
    } else {
      $('div.nav-top-link').fadeOut();
    }
  });
  $('div.nav-top-link').click(function(){
    $('html, body').animate({scrollTop:'0px'}, 800);
    return false;
  });
});

// Smooth scrolling
$(document).ready(function() {
  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if ($("body").hasClass("main")) {
        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 500);
          return false;
        }
      } else {
        if (target.length) {
          $('html,body').animate({
            scrollTop: $(window.location.hash).offset().top
          },500);
          return false;
        }
      }
    }
  });
});

// Form width placeholder
$("form :input").each(function(index, elem) {
  var eId = $(elem).attr("id");
  var label = null;
  if (eId && (label = $(elem).parents("form").find("label[for="+eId+"]")).length == 1) {
    if (!$(elem).hasClass("captcha")) {
      var placeholder = $(label).text();
      var tmp_placeholder = placeholder.replace("Pflichtfeld", "");
      var new_placeholder = tmp_placeholder.trim();
      $(elem).attr("placeholder", new_placeholder);
      $(label).addClass('hide');
    }
  }
});

